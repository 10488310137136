<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        clip-rule="evenodd"
        d="M38 36.6685V17.3253C38 16.7949 37.7893 16.2862 37.4142 15.9111L35.0858 13.5827L32.7574 11.2542C32.3823 10.8792 31.8736 10.6685 31.3431 10.6685H30V17.6685C30 18.773 29.1046 19.6685 28 19.6685H16C14.8954 19.6685 14 18.773 14 17.6685V10.6685H12C10.8954 10.6685 10 11.5639 10 12.6685V36.6685C10 37.773 10.8954 38.6685 12 38.6685H14V28.6685C14 27.5639 14.8954 26.6685 16 26.6685H32C33.1046 26.6685 34 27.5639 34 28.6685V38.6685H36C37.1046 38.6685 38 37.773 38 36.6685ZM16 28.6685H32V38.6685H16V28.6685ZM12 40.6685H16H32H36C38.2091 40.6685 40 38.8776 40 36.6685V17.3253C40 16.2644 39.5786 15.247 38.8284 14.4969L36.5 12.1685L34.1716 9.84003C33.4214 9.08988 32.404 8.66846 31.3431 8.66846H28H16H12C9.79086 8.66846 8 10.4593 8 12.6685V36.6685C8 38.8776 9.79086 40.6685 12 40.6685ZM16 10.6685H28V17.6685H16V10.6685ZM26 11.6685H24V16.6685H26V11.6685ZM18.5 30.6685C18.2239 30.6685 18 30.8923 18 31.1685C18 31.4446 18.2239 31.6685 18.5 31.6685H29.5C29.7761 31.6685 30 31.4446 30 31.1685C30 30.8923 29.7761 30.6685 29.5 30.6685H18.5ZM18 34.1685C18 33.8923 18.2239 33.6685 18.5 33.6685H29.5C29.7761 33.6685 30 33.8923 30 34.1685C30 34.4446 29.7761 34.6685 29.5 34.6685H18.5C18.2239 34.6685 18 34.4446 18 34.1685ZM18.5 36.6685C18.2239 36.6685 18 36.8923 18 37.1685C18 37.4446 18.2239 37.6685 18.5 37.6685H23.5C23.7761 37.6685 24 37.4446 24 37.1685C24 36.8923 23.7761 36.6685 23.5 36.6685H18.5Z"
        fill="inherit"
        fill-rule="evenodd"
    />
  </decorator-icon>
</template>

<script>
import DecoratorIcon from "../decorator/dp-icon-decorator";
import MixinIcon from "../mixin/dp-icon-mixin";

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: "dp-save-tool-icon",
  mixins: [MixinIcon],
  components: {
    DecoratorIcon,
  },
  data() {
    return {
      viewBox: "0 0 48 48",
    };
  },
};
</script>
